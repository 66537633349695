<script setup>
import { ref, onMounted } from "vue";
import { post } from "./../mixins/http_handler";
import { getBanners } from "../mixins/fetch_banners.js";
import { Cloudinary } from "@cloudinary/url-gen";
import { Delivery } from "@cloudinary/url-gen/actions";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
// import { defineProps } from "vue";

const schema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^(\d{10})$/, "Phone number is in incorrect format"),
  stateOfInterest: Yup.string().required("State of interest is required"),
  cityOfInterest: Yup.string().required("City of interest is required"),
  modelOfInterest: Yup.string().required("Model of interest is required"),
  cashInvestment: Yup.string().required("Cash Investment is required"),
});

const customer = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  stateOfInterest: "",
  cityOfInterest: "",
  modelOfInterest: "",
  cashInvestment: "",
});


const showThankYou = ref(false);
const banners = ref([]);
const states = ref([]);
const cld = new Cloudinary({
  cloud: {
    cloudName: "ilovekickboxing",
  },
});
// const { errorMessage, customer.firstName } = useField('firstName', isRequired);
// const props = defineProps({
//   studioDetails: Object,
// });

const getGetStartedBanners = () => {
  getBanners("Franchise", "background").then((response) => {
    if(response!= null){
    response.forEach((value) => {
      const myImage = cld.image(value.public_id);
      myImage.delivery(Delivery.format("auto")).quality("auto:eco");
      value.imgurl = myImage.toURL();
      banners.value.push(value);
    });
  }
  });
};

const getStates = () => {
  post('/', {
        query: `query {
    states(first: 300, page: 1, active: true) {
        data {
            ID
            Code
            State
            country {
              CountryName
              CountryCode
            }
        }
    }
}`
    })
    .then((response) => {
      states.value.push({ID: 57,Code: "AB",State: "Canada"});
      let stateResponse = response.data.data.states.data
      stateResponse.forEach((value) => {
        if(value.country.CountryCode == 'US') {
          states.value.push(value);
        }
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const onSubmit = () => {
  let windowReference = window.open();
  let customerInfo = "[" +
    '{name: "email", value:"' +
    customer.value.email +
    '"}' +
    '{name: "first_name", value:"' +
    customer.value.firstName +
    '"}' +
    '{name: "last_name", value:"' +
    customer.value.lastName +
    '"}' +
    '{name: "phone", value:"' +
    customer.value.phone +
    '"}' +
    '{name: "state_code", value:"' +
    customer.value.stateOfInterest +
    '"}' +
    '{name: "city", value:"' +
    customer.value.cityOfInterest +
    '"}' +
    '{name: "start_date", value:"' +
    customer.value.modelOfInterest +
    '"}' +
    '{name: "cash_investment", value:"' +
    customer.value.cashInvestment +
    '"}' +
    "]";
  post(
    "/",
    {
      query:
        `mutation {
              recordFranchiseOptin(
                input: { customer: ` +
        customerInfo +
        ` }
              ){
                  franchise_optins_id
                  first_name
                  last_name
                  email
                  phone
                  state_code
                  city
                  cash_investment
                  start_date
                  created_at
              }
            }`,
    },
    {
      Accept: "application/json",
      Authorization: process.env.VUE_APP_API_AUTH_TOKEN,
    }
  )
    .then((response) => {
      showThankYou.value = true;
      if(response.data.data.recordFranchiseOptin){
        windowReference.location =process.env.BASE_URL+"/thank-you";
        // windowReference.location = process.env.VUE_APP_HIGHLEVEL_URL +
        // "first_name=" + customer.value.firstName +
        // "&last_name=" + customer.value.lastName +
        // "&phone=" + customer.value.phone +
        // "&email=" + customer.value.email +
        // "&stateofinterest=" + customer.value.stateOfInterest +
        // "&cityofinterest=" + customer.value.cityOfInterest +
        // "&modelOfInterest=" + customer.value.modelOfInterest +
        // "&cashinvestment=" + customer.value.cashInvestment;
       } else {
           windowReference.close();
       }
    })
    .catch((err) => {
      windowReference.close();
      console.log(err);
    });
};

onMounted(() => {
  getGetStartedBanners();
  getStates();
});
</script>

<template>
  <section class="section-wrapper getstarted-wrapper" id="getstarted">
    <div class="container-fluid position-relative mt-5">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center mb-5"><span class="heart_icon"></span></div>
          <h2 class="text-center">Ready to meet us on the mat?</h2>
          <p class="col-10 col-md-8 mx-auto text-center text-dark">Please complete the form and a representative from our team will be in touch shortly!</p>
          <div class="col-12 col-md-8 mx-auto">
          <template v-if="showThankYou">
            <div class="thankyou_wrapper">
              <h1>Thank you for contacting us</h1>
              <p>We will get back to you soon.</p>
            </div>
          </template>
          <template v-else>
            <div class="form_getstarted">
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="row row-mrl-30">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>First Name*</label>
                    <Field
                      type="text"
                      v-model="customer.firstName"
                      name="firstName"
                      id="firstName"
                      class="form-control"
                      :class="{ 'is-invalid': errors.firstName }"
                    />
                    <div class="invalid-feedback">{{ errors.firstName }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Last Name*</label>
                    <Field
                      type="text"
                      v-model="customer.lastName"
                      name="lastName"
                      id="lastName"
                      class="form-control"
                      :class="{ 'is-invalid': errors.lastName }"
                    />
                    <div class="invalid-feedback">{{ errors.lastName }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Phone Number*</label>
                    <Field
                      type="text"
                      v-model="customer.phone"
                      name="phone"
                      id="phone"
                      class="form-control"
                      maxlength="10"
                      minlength="10"
                      :class="{ 'is-invalid': errors.phone }"
                    />
                    <div class="invalid-feedback">{{ errors.phone }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Email*</label>
                    <Field
                        type="text"
                        v-model="customer.email"
                        name="email"
                        id="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                  </div>
                </div>
              </div>
              <div class="row row-mrl-30">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>City of interest**</label>
                    <Field
                      type="text"
                      v-model="customer.cityOfInterest"
                      name="cityOfInterest"
                      id="cityOfInterest"
                      class="form-control"
                      :class="{ 'is-invalid': errors.cityOfInterest }"
                    />
                    <div class="invalid-feedback">{{ errors.cityOfInterest }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>State of interest*</label>
                    <Field
                        as="select"
                        v-model="customer.stateOfInterest"
                        name="stateOfInterest"
                        id="stateOfInterest"
                        class="form-control"
                        :class="{ 'is-invalid': errors.stateOfInterest }"
                    >
                      <option v-for="state in states" :key="state" :value="state.State">{{ state.State }}</option>
                    </Field>
                    <!-- <Field
                      v-model="customer.stateOfInterest"
                      name="stateOfInterest"
                      as="select"
                      id="stateOfInterest"
                      class="form-control"
                      :class="{ 'is-invalid': errors.stateOfInterest }"
                    >
                      <option>California</option>
                    </Field> -->
                    <div class="invalid-feedback">{{ errors.stateOfInterest }}</div>
                  </div>
                </div>
              </div>
              <div class="row row-mrl-30">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Model of Interest *</label>
                    <Field
                      v-model="customer.modelOfInterest"
                      name="modelOfInterest"
                      as="select"
                      id="modelOfInterest"
                      class="form-control"
                      :class="{ 'is-invalid': errors.modelOfInterest }"
                    >
                      <option value="Lightweight">Lightweight</option>
                      <option value="Standard">Standard</option>
                    </Field>
                    <div class="invalid-feedback">{{ errors.modelOfInterest }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Cash Available For Investment *</label>
                    <Field
                      v-model="customer.cashInvestment"
                      name="cashInvestment"
                      as="select"
                      id="cashInvestment"
                      class="form-control"
                      :class="{ 'is-invalid': errors.cashInvestment }"
                    >
                      <option value="Under $5000">Under $5000</option>
                      <option value="$5000 - $10,000">$5000 - $10,000</option>
                      <option value="$10,000 - $50,000">$10,000 - $50,000</option>
                      <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                      <option value="$100,000 - $250,000">$100,000 - $250,000</option>
                      <option value="$250,000 - $500,000">$250,000 - $500,000</option>
                      <option value="$500,000+">$500,000+</option>
                    </Field>
                    <div class="invalid-feedback">{{ errors.cashInvestment }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group text-center">
                    <button
                      id="book_now"
                      type="submit"
                      class="btn btn-primary w-100"
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </template>
      </div>
      <br>
      <div class="row">
        <div class="col-lg-12 text-center-dmsans">
          This website and the franchise sales information on this site do not constitute an offer to sell a franchise. The offer of a franchise can only be made through the delivery of a franchise disclosure document. Certain states require that we register the franchise disclosure document in those states. The communications on this website are not directed by us to the residents of any of those states. Moreover, we will not offer or sell franchises in those states until we have registered the franchise (or obtained an applicable exemption from registration) and delivered the franchise disclosure document to the prospective franchisee in compliance with applicable law.
        </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>
