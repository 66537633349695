<script setup>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import { getBanners } from "./../mixins/fetch_banners.js";
import { Cloudinary } from "@cloudinary/url-gen";
import Navbar from "@/components/Navbar.vue";
import GetStarted from "@/components/GetStarted.vue";
import Footer from "@/components/Footer.vue";
// import { useRoute } from 'vue-router'
import { post } from "./../mixins/http_handler";
import { ref, onMounted, onBeforeMount } from "vue";
import { Delivery } from "@cloudinary/url-gen/actions";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const metaTags = ref([]);
const metaScripts = ref([]);
const metaNoScripts = ref([]);
const metaLinks = ref([]);
// const route = useRoute()
const isMobile = ref(false);

import { useMeta } from "vue-meta";

const desktopBanners = ref([]);
const mobileBanners = ref([]);
const missionBanners = ref([]);
const missionBanners2 = ref([]);
const whyUsBanners = ref([]);
const LightWeightBanners = ref([]);
const ProcessBanners = ref([]);
const ProcessBanners2 = ref([]);
const SuccessBanners = ref([]);
const SuccessBanners2 = ref([]);

const cld = new Cloudinary({
  cloud: {
    cloudName: "ilovekickboxing",
  },
});

const sliderOptionsSUCCESS = {
  rewind: true,
  type: "slide",
  perPage: 1,
  autoplay: true,
  pagination: true,
  arrows: false,
};

const sliderOptions2 = {
  rewind: true,
  type: "slide",
  perPage: 2,
  autoplay: true,
  pagination: true,
  arrows: false,
  padding: "0rem",
  breakpoints: {
    640: {
      perPage: 1,
      pagination: true,
    },
  },
};

const sliderOptions = {
  rewind: true,
  type: "fade",
  perPage: 1,
  autoplay: true,
  pagination: false,
  arrows: false,
};

const getMainBanners = () => {
  getBanners("Franchise", "banner").then(() => {
    desktopBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867568/ILKB_Fran_Dev_Lightweight.01_btr1yk.jpg",
      device: "Desktop",
    });

    mobileBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867568/ILKB_Fran_Dev_Lightweight.01_btr1yk.jpg",
      device: "Mobile",
    });
  });
};

const getMissionBanners = () => {
  getBanners("Franchise", "mission-1").then(() => {
    missionBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867568/ILKB_Fran_Dev_Lightweight.02_mjlx6o.jpg",
      device: "Desktop",
    });
    missionBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867568/ILKB_Fran_Dev_Lightweight.02_mjlx6o.jpg",
      device: "Mobile",
    });
  });
};
const getWhyUsBanners = () => {
  whyUsBanners.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867569/ILKB_Fran_Dev_Lightweight.03_o8ftvz.jpg",
    device: "Desktop",
  });
  whyUsBanners.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867569/ILKB_Fran_Dev_Lightweight.03_o8ftvz.jpg",
    device: "Mobile",
  });
};
const getLightWeightBanners = () => {
  getBanners("Franchise", "mission-1").then(() => {
    LightWeightBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867570/ILKB_Fran_Dev_Lightweight.04_cm9wes.jpg",
      device: "Desktop",
    });
    LightWeightBanners.value.push({
      imgurl:
        "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867570/ILKB_Fran_Dev_Lightweight.04_cm9wes.jpg",
      device: "Mobile",
    });
    // });
  });
};
const getMetaInfo = () => {
  let href = process.env.VUE_APP_META_TAG_URL;
  post("/", {
    query:
      `{metaTags(url:"` +
      href +
      `")
          {website_seo_tracking_tags_id
              page_name
              page_section
              tracking_type
              tracking_comment
              tracking_sequence
              tracking_value
            }}`,
  })
    .then((response) => {
      const metaTagsApi = response.data.data.metaTags;
      /* Metas */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "meta" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach((metaTag) => {
          metaTags.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* Scripts */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "script" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach((metaTag) => {
          metaScripts.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* No Scripts */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "noscript" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach((metaTag) => {
          metaNoScripts.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* Links */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "link" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach((metaTag) => {
          metaLinks.value.push(...JSON.parse(metaTag.tracking_value));
        });
    })
    .catch((err) => {
      console.log(err);
    });
};
const checkIsMobile = () => {
  let width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};

const getProcessBanners = () => {
  ProcessBanners.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867291/ILKB_Fran_Dev_Standard.05_if1tgr.jpg",
    device: "Desktop",
  });
  ProcessBanners.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867291/ILKB_Fran_Dev_Standard.05_if1tgr.jpg",
    device: "Mobile",
  });

  ProcessBanners2.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867291/ILKB_Fran_Dev_Standard.06_flbjhz.jpg",
    device: "Desktop",
  });
  ProcessBanners2.value.push({
    imgurl:
      "https://res.cloudinary.com/ilovekickboxing/image/upload/v1722867291/ILKB_Fran_Dev_Standard.06_flbjhz.jpg",
    device: "Mobile",
  });
};
onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
  getMetaInfo();
  getMainBanners();
  getMissionBanners();
  getLightWeightBanners();
  getWhyUsBanners();
  getProcessBanners();
});

const scrollToView = (id) => {
  console.log(id);
  let element = document.getElementById(id);
  let headerOffset = 80;
  let elementPosition = element.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });

  // element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
};

useMeta({
  title: "Franchise - iLoveKickboxing",
  meta: metaTags.value,
  script: metaScripts.value,
  noscript: metaNoScripts.value,
  link: metaLinks.value,
});
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="home-wrapper">
    <Header />
    <!-- Start Banner -->
    <section class="home-banner-wrapper position-relative" id="herobanner">
      <template v-if="isMobile">
        <div class="home_banner_image">
          <div class="homebanner_slider">
            <div class="homebanner_slider_main">
              <template v-if="mobileBanners && mobileBanners.length > 0">
                <Splide :options="sliderOptions">
                  <SplideSlide v-for="(banner, index) in mobileBanners" :key="index">
                    <div class="img-wrap justify-content-center">
                      <img class="img-fluid" :src="banner.imgurl" />
                    </div>
                  </SplideSlide>
                </Splide>
              </template>
            </div>
          </div>
          <div class="home_banner_content">
            <div class="container-fluid">
              <div class="row align-items-md-center">
                <div class="col-12 col-md-7 col-lg-7 col-xl-6 position-static">
                  <div class="content-wrap">
                    <div>
                      <h1 class="banner_title">Bring ILKB classes</h1>
                      <h2 class="banner_sub_title">
                        <strong>TO YOUR COMMUNITY</strong>
                        <!-- <span class="font-PPFormulaRegullar">Franchise Opportunity</span> -->
                      </h2>
                      <div class="d-flex align-items-center">
                        <p class="banner_desc">
                          Discover the most flexible way to launch a fitness
                          side-hustle with kickboxing! The ILKB Lightweight Program
                          delivers maximum value at minimal cost with:
                        </p>

                        <button type="button" class="btn btn-circle" @click="$filters.goto('getstarted')">
                          Learn More
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="homebanner_slider">
          <div class="homebanner_slider_main">
            <template v-if="desktopBanners && desktopBanners.length > 0">
              <Splide :options="sliderOptions">
                <SplideSlide v-for="(desktopBanners, index) in desktopBanners" :key="index">
                  <div class="img-wrap justify-content-center">
                    <img class="img-fluid" :src="desktopBanners.imgurl" />
                  </div>
                </SplideSlide>
              </Splide>
            </template>
          </div>
        </div>
        <div class="home_banner_content">
          <div class="container-fluid">
            <div class="row align-items-md-center">
              <div class="col-12 col-md-7 col-lg-7 col-xl-6 position-static">
                <div class="content-wrap">
                  <div>
                    <h1 class="banner_title">Bring ILKB classes</h1>
                    <h2 class="banner_sub_title">
                      <strong>TO YOUR COMMUNITY</strong>
                      <!-- <span class="font-PPFormulaRegullar">Franchise Opportunity</span> -->
                    </h2>
                    <div class="d-flex align-items-center">
                      <p class="banner_desc">
                        Discover the most flexible way to launch a fitness
                        side-hustle with kickboxing! The ILKB Lightweight Program
                        delivers maximum value at minimal cost with:
                      </p>

                      <button type="button" class="btn btn-circle" @click="$filters.goto('getstarted')">
                        Learn More
                      </button>
                    </div>
                    <ul class="hero-list">
                      <li>
                        <b>Location Flexibility:</b> Teach classes in studios,
                        gyms, churches... anywhere you have the space to kick it!
                      </li>
                      <li>
                        <b>Ready-made Workouts:</b> 45-minute, grab-and-go class
                        plans done for you
                      </li>
                      <li>
                        <b>Proprietary Technology:</b> Dedicated coaching screens
                        to elevate every class
                      </li>
                      <li>
                        <b>Localized Website:</b> A fully branded website
                        integrated within our national site
                      </li>
                      <li>
                        <b>Marketing Expertise:</b> Access to proven strategies
                        and ready-to-use tools
                      </li>
                      <li>
                        <b>Wholesale Account:</b> Provide premium branded gear to
                        your clients
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- End Banner -->
    </section>
    <!-- <Banner/> -->
    <Navbar />
    <template v-if="isMobile">
      <div class="row align-items-md-center">
        <div class="col-12 col-md-7 col-lg-7 col-xl-6 position-static">
          <div class="content-wrap">
            <div class="hero-list-mobile mt-2 mb-2">
              <div class="hero-item">
                <div class="hero-title"><b>Location Flexibility</b></div>
                <div class="hero-text">Teach classes in studios,
                  gyms, churches... anywhere you have the space to kick it!</div>
              </div>
              <div class="hero-item">
                <div class="hero-title"><b>Ready-made Workouts</b></div>
                <div class="hero-text">45-minute, grab-and-go class
                  plans done for you.</div>
              </div>
              <div class="hero-item">
                <div class="hero-title"><b>Proprietary Technology</b></div>
                <div class="hero-text">Dedicated coaching screens
                  to elevate every class.</div>
              </div>
              <div class="hero-item">
                <div class="hero-title"><b>Localized Website</b></div>
                <div class="hero-text">A fully branded website
                  integrated within our national site.</div>
              </div>
              <div class="hero-item">
                <div class="hero-title"><b>Marketing Expertise</b></div>
                <div class="hero-text">Access to proven strategies
                  and ready-to-use tools.</div>
              </div>
              <div class="hero-item">
                <div class="hero-title"><b>Wholesale Account</b></div>
                <div class="hero-text">Provide premium branded gear to
                  your clients.</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne" @click="scrollToView('headingOne')">
              <span class="nav_count_nos">01</span> Our Mission
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start OurMission -->
              <section class="section-wrapper what-you-get-wrapper" id="ourmission">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-md-9 col-xl-8 mx-auto text-center">
                      <h1 class="section_title">I Love Kickboxing</h1>
                      <h2 class="section_subtitle">
                        <span class="font-PPFormulaRegullar">CLASS STRUCTURE</span>
                      </h2>
                    </div>
                  </div>
                  <div class="row align-items-center justify-content-between">
                    <div class="col-12 col-md-6 mb-3 mb-md-0">
                      <div class="mission_imgbox">
                        <div v-for="(banner, index) in missionBanners" :key="index">
                          <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap mission_imgone">
                            <img :src="banner.imgurl" />
                          </div>
                          <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap mission_imgone">
                            <img :src="banner.imgurl" />
                          </div>
                        </div>
                      </div>
                      <div class="mission_imgbox">
                        <div v-for="(banner, index) in missionBanners2" :key="index">
                          <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap mission_imgtwo">
                            <img :src="banner.imgurl" />
                          </div>
                          <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap mission_imgtwo">
                            <img :src="banner.imgurl" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="d-flex flex-column">
                        <p>
                          At I Love Kickboxing, our energetic 45-minute classes
                          are designed to deliver a fun, full-body workout that
                          anyone at any fitness level can enjoy. Each session
                          kicks off with a dynamic warm-up to get your blood
                          pumping, followed by intense bag work that lets you
                          unleash your inner fighter. We finish strong with
                          targeted core exercises and a cool-down to ensure you
                          leave feeling accomplished and invigorated.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lovethefight_wrp">
                  <div class="lovefirstrow">
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                  </div>
                  <div class="lovefirstrow lovesecondrow">
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                    <div class="lovetext"><span>Love</span>TheFight</div>
                  </div>
                </div>
              </section>
              <!-- End OurMission -->
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
              @click="scrollToView('headingTwo')">
              <span class="nav_count_nos">02</span> Why us
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start WhyUs -->
              <section class="section-wrapper section_whyus" id="whyus">
                <div class="container-fluid">
                  <div class="row align-items-end justify-content-between">
                    <div class="col-12 col-md-6">
                      <h1 class="section_title">
                        Why Own an I Love Kickboxing?
                      </h1>
                      <h2 class="section_subtitle">
                        Become an ILKB franchise partner
                      </h2>
                      <p>
                        By investing in an I Love Kickboxing franchise, you�re
                        not just starting a business�you�re joining a movement
                        dedicated to uplifting individuals and fostering a
                        culture of health and wellness. Join us in making a
                        difference and inspire others to achieve their fitness
                        goals while building a thriving community.
                      </p>
                      <p>
                        Our members experience results driven by passion,
                        accountability, and empowerment. We offer people the
                        chance to step away from their busy lives and immerse
                        themselves in a full-body kickboxing-style workout that
                        transforms their self-confidence and pushes them to
                        reach new heights. We love what we do, and we can�t wait
                        to help you bring this transformative experience to your
                        community.
                      </p>
                      <!-- <div class="nums_wrp">
                  
                      </div> -->
                    </div>
                    <div class="col-12 col-md-6">
                      <div v-for="(banner, index) in whyUsBanners" :key="index">
                        <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap whyus_img">
                          <img :src="banner.imgurl" />
                          <br /><br /><br /><br /><br />
                        </div>
                        <div v-if="whyUsBanners.device == 'Mobile'" class="d-block d-md-none img-wrap whyus_img">
                          <img :src="banner.imgurl" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-12 col-md-5">
                      <div class="whytextitem_card">
                        <div class="whytextitem_head">
                          <div class="whytextitem_icon">
                            <img src="../assets/icon_business_model.png" alt="Buiness Model" class="img-fluid" />
                          </div>
                          <h6>Turn-key Business Model</h6>
                        </div>
                        <div class="whytextitem_desc">
                          I Love Kickboxing offers a proven business model that
                          has been tested and streamlined for new owners. With
                          comprehensive support and training, franchisees can
                          confidently bring high-energy kickboxing classes to
                          their communities.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="whytextitem_card">
                        <div class="whytextitem_head">
                          <div class="whytextitem_icon">
                            <img src="../assets/icon_support.png" alt="Support & Education" class="img-fluid" />
                          </div>
                          <h6>Top-Notch Support</h6>
                        </div>
                        <div class="whytextitem_desc">
                          We offer dedicated support with franchise business
                          coaches who provide personalized guidance and
                          strategic consultation. Additionally, new programs
                          come with comprehensive support materials and
                          resources, empowering franchisees to effectively
                          implement and optimize their operations.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="whytextitem_card">
                        <div class="whytextitem_head">
                          <div class="whytextitem_icon">
                            <img src="../assets/icon_marketing.png" alt="Marketing" class="img-fluid" />
                          </div>
                          <h6>Established Branding</h6>
                        </div>
                        <div class="whytextitem_desc">
                          Become a part of a renowned kickboxing fitness brand
                          with years of industry experience. Enjoy the
                          credibility, recognition, and support that come with
                          owning an ILKB franchise, setting your business up for
                          success from day one.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="whytextitem_card">
                        <div class="whytextitem_head">
                          <div class="whytextitem_icon">
                            <img src="../assets/icon_community.png" alt="Community Empowerment" class="img-fluid" />
                          </div>
                          <h6>Community Empowerment</h6>
                        </div>
                        <div class="whytextitem_desc">
                          As a franchise partner, you have the rewarding
                          opportunity to cultivate strong connections within
                          your already established community and attract new
                          prospects with this additional service.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- End WhyUs -->
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
              @click="scrollToView('headingThree')">
              <span class="nav_count_nos">03</span> Success Stories
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start SuccessStories -->
              <section class="section-wrapper section_oursuccessstory" id="successstories">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-md-9 col-xl-8 mx-auto text-center">
                      <h1 class="section_title" style="color: red">
                        How it works
                      </h1>
                      <h2 class="section_subtitle">
                        <span class="font-pp_formula_condensedbold">LIGHTWEIGHT MODEL</span>
                      </h2>
                    </div>
                  </div>
                  <div class="row align-items-center justify-content-between">
                    <div class="col-12 col-md-6 mb-3 mb-md-0">
                      <!-- Responsive Video Container -->
                      <div class="responsive-video">
                        <video controls>
                          <source
                            src="https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB/CSS/ILKB_CSS%202.mp4?_t=1729523858"
                            type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="d-flex flex-column">
                        <p>
                          With our I Love Kickboxing Lightweight model, you will
                          purchase the required number of kickboxing bags and
                          our integrated Coaching Screen System to successfully
                          run classes at your existing fitness or karate
                          studio.<br /><br />
                          When it's time for your ILKB classes to start, you
                          simply set up our portable and easy-to-move kickboxing
                          bags in your studio space. Turn on the integrated
                          technology at the start of class, and bring the energy
                          while coaching members through the exercises to
                          complete the class!<br /><br />
                          When classes are done for the day or you have other
                          activities or services planned, simply put away your
                          ILKB bags in their designated storage area and
                          continue your operations as normal. Yes, it�s really
                          that simple!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- End SuccessStories -->
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
              @click="scrollToView('headingFour')">
              <span class="nav_count_nos">04</span> Requirements
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start Investment Requirements -->
              <section class="section-wrapper section_whyus" id="requirements">
                <div class="container-fluid">
                  <div class="row justify-content-between">
                    <div class="col-12 col-md-8">
                      <h1 class="section_title" style="color: red">
                        Ready to get started?
                      </h1>
                      <h2 class="section_subtitle">Franchise Opportunity</h2>
                      <p>
                        We understand what it takes, and our goal is to ensure
                        you're fully prepared to launch your I Love Kickboxing
                        business. We offer affordable fixed monthly fees for
                        franchise partners. Before proceeding, we'll verify that
                        you meet the financial requirements to become a
                        franchise partner
                      </p>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="investment_textbox">
                        <h3>2 or 3</h3>
                        <p>YEAR TERM</p>
                      </div>
                      <div class="investment_textbox">
                        <h3><span class="currency_text">$</span>5,000</h3>
                        <p>CASH FOR INVESTMENT</p>
                      </div>
                      <div class="investment_textbox">
                        <h3>~1,000 sq. ft</h3>
                        <p>SPACE REQUIREMENT</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- End Investment Requirements -->
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
              @click="scrollToView('headingFive')">
              <span class="nav_count_nos">05</span> Process
            </button>
          </h2>
          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFour"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start Process -->
              <section class="section-wrapper section_process" id="process">
                <div class="container-fluid">
                  <div class="requirement_full_img mb-5">
                    <div v-for="(banner, index) in ProcessBanners" :key="index">
                      <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap">
                        <img :src="banner.imgurl" />
                      </div>
                      <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap">
                        <img :src="banner.imgurl" />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-12 col-md-4">
                      <h2>7 STEPS TO OWNERSHIP</h2>
                      <div v-for="(banner, index) in ProcessBanners2" :key="index">
                        <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap stepowner_img">
                          <img :src="banner.imgurl" />
                        </div>
                        <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap stepowner_img">
                          <img :src="banner.imgurl" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7">
                      <div class="">
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>01</span> FILL OUT THE FORM BELOW
                          </h4>
                          <p>
                            Once we receive your form, a representative will
                            reach out to discuss the next steps.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>02</span> COMPLETE THE OFFICIAL APPLICATION
                          </h4>
                          <p>
                            Provide us with insight into your business
                            experience, financial capabilities, goals and
                            interests, and management style.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>03</span> REVIEW OUR FRANCHISE DISCLOSURE
                            DOCUMENT (FDD)
                          </h4>
                          <p>
                            We send you the official Franchise Disclosure
                            Document that outlines financial and performance
                            information.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>04</span> COMPLETE FRANCHISE VALIDATION AND
                            DUE DILIGENCE
                          </h4>
                          <p>
                            Talk to current Franchise Owners to gain insights
                            about day-to-day operations and their overall
                            experience.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>05</span> ATTEND DISCOVERY SESSIONS
                          </h4>
                          <p>
                            Meet with our team to learn more about ILKB and
                            confirm it's the right opportunity for you.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>06</span> EXECUTE FRANCHISE AGREEMENT
                          </h4>
                          <p>
                            Once our team approves your application and we have
                            all the legally required documentation, we create
                            your Franchise Agreement.
                          </p>
                        </div>
                        <div class="stepsown_row">
                          <h4 class="stepsown_title">
                            <span>07</span> FIND YOUR LOCATION AND GET STARTED
                          </h4>
                          <p>
                            After the Franchise Agreement is signed, our team
                            works with you on site selection and marketing to
                            support your journey toward opening day.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- End Process -->
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
              @click="scrollToView('headingSix')">
              <span class="nav_count_nos">06</span> Press
            </button>
          </h2>
          <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Start Press -->
              <section class="section-wrapper section_press" id="press">
                <div class="inthenews_wrap">
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                  <div class="inthenews_text">In the <span>News</span></div>
                </div>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <Splide :options="sliderOptions2">
                        <SplideSlide>
                          <div class="press_box">
                            <div class="press_card">
                              <h3 class="press_title">
                                9ROUND KICKBOXING ACQUIRES I LOVE KICKBOXING
                              </h3>
                              <div class="press_link">
                                <a href="https://www.franchisetimes.com/franchise_mergers_and_acquisitions/9round-kickboxing-acquires-ilovekickboxing/article_7c1ad296-e881-11ee-a394-2f6e523631d1.html"
                                  target="_blank">
                                  Franchise Times
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 1L1 15" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" />
                                    <path d="M3.33301 1H14.9997V12.6667" stroke="currentColor" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="press_box">
                            <div class="press_card">
                              <h3 class="press_title">
                                SEE WHY THE LOCALIST SD LOVES KICKBOXING
                              </h3>
                              <div class="press_link">
                                <a href="https://fox5sandiego.com/thelocalistsd/see-why-we-love-kickboxing/"
                                  target="_blank">
                                  Fox 5 San Diego
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 1L1 15" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" />
                                    <path d="M3.33301 1H14.9997V12.6667" stroke="currentColor" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                  </div>
                </div>
              </section>
              <!-- End Press -->
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <section class="section-wrapper what-you-get-wrapper" id="ourmission">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-9 col-xl-8 mx-auto text-center">
              <h1 class="section_title">I Love Kickboxing</h1>
              <h2 class="section_subtitle">
                <span class="font-PPFormulaRegullar">CLASS STRUCTURE</span>
              </h2>
            </div>
          </div>
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <div class="mission_imgbox">
                <div v-for="(banner, index) in missionBanners" :key="index">
                  <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap mission_imgone">
                    <img :src="banner.imgurl" />
                  </div>
                  <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap mission_imgone">
                    <img :src="banner.imgurl" />
                  </div>
                </div>
              </div>
              <div class="mission_imgbox">
                <div v-for="(banner, index) in missionBanners2" :key="index">
                  <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap mission_imgtwo">
                    <img :src="banner.imgurl" />
                  </div>
                  <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap mission_imgtwo">
                    <img :src="banner.imgurl" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="d-flex flex-column">
                <p>
                  At I Love Kickboxing, our energetic 45-minute classes are
                  designed to deliver a fun, full-body workout that anyone at
                  any fitness level can enjoy. Each session kicks off with a
                  dynamic warm-up to get your blood pumping, followed by intense
                  bag work that lets you unleash your inner fighter. We finish
                  strong with targeted core exercises and a cool-down to ensure
                  you leave feeling accomplished and invigorated.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="lovethefight_wrp">
          <div class="lovefirstrow">
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
          </div>
          <div class="lovefirstrow lovesecondrow">
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
            <div class="lovetext"><span>Love</span>TheFight</div>
          </div>
        </div>
      </section>
      <section class="section-wrapper section_whyus" id="whyus">
        <div class="container-fluid">
          <div class="row align-items-end justify-content-between">
            <div class="col-12 col-md-6">
              <h1 class="section_title">Why Own an I Love Kickboxing?</h1>
              <h2 class="section_subtitle">Become an ILKB franchise partner</h2>
              <p>
                By investing in an I Love Kickboxing franchise, you�re not just
                starting a business�you�re joining a movement dedicated to
                uplifting individuals and fostering a culture of health and
                wellness. Join us in making a difference and inspire others to
                achieve their fitness goals while building a thriving community.
              </p>
              <p>
                Our members experience results driven by passion,
                accountability, and empowerment. We offer people the chance to
                step away from their busy lives and immerse themselves in a
                full-body kickboxing-style workout that transforms their
                self-confidence and pushes them to reach new heights. We love
                what we do, and we can�t wait to help you bring this
                transformative experience to your community.
              </p>
              <!-- <div class="nums_wrp">
                  
                      </div> -->
            </div>
            <div class="col-12 col-md-6">
              <div v-for="(banner, index) in whyUsBanners" :key="index">
                <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap whyus_img">
                  <img :src="banner.imgurl" />
                  <br /><br /><br /><br /><br />
                </div>
                <div v-if="whyUsBanners.device == 'Mobile'" class="d-block d-md-none img-wrap whyus_img">
                  <img :src="banner.imgurl" />
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-12 col-md-5">
              <div class="whytextitem_card">
                <div class="whytextitem_head">
                  <div class="whytextitem_icon">
                    <img src="../assets/icon_business_model.png" alt="Buiness Model" class="img-fluid" />
                  </div>
                  <h6>Turn-key Business Model</h6>
                </div>
                <div class="whytextitem_desc">
                  I Love Kickboxing offers a proven business model that has been
                  tested and streamlined for new owners. With comprehensive
                  support and training, franchisees can confidently bring
                  high-energy kickboxing classes to their communities.
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="whytextitem_card">
                <div class="whytextitem_head">
                  <div class="whytextitem_icon">
                    <img src="../assets/icon_support.png" alt="Support & Education" class="img-fluid" />
                  </div>
                  <h6>Top-Notch Support</h6>
                </div>
                <div class="whytextitem_desc">
                  We offer dedicated support with franchise business coaches who
                  provide personalized guidance and strategic consultation.
                  Additionally, new programs come with comprehensive support
                  materials and resources, empowering franchisees to effectively
                  implement and optimize their operations.
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="whytextitem_card">
                <div class="whytextitem_head">
                  <div class="whytextitem_icon">
                    <img src="../assets/icon_marketing.png" alt="Marketing" class="img-fluid" />
                  </div>
                  <h6>Established Branding</h6>
                </div>
                <div class="whytextitem_desc">
                  Become a part of a renowned kickboxing fitness brand with
                  years of industry experience. Enjoy the credibility,
                  recognition, and support that come with owning an ILKB
                  franchise, setting your business up for success from day one.
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="whytextitem_card">
                <div class="whytextitem_head">
                  <div class="whytextitem_icon">
                    <img src="../assets/icon_community.png" alt="Community Empowerment" class="img-fluid" />
                  </div>
                  <h6>Community Empowerment</h6>
                </div>
                <div class="whytextitem_desc">
                  As a franchise partner, you have the rewarding opportunity to
                  cultivate strong connections within your already established
                  community and attract new prospects with this additional
                  service.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-wrapper section_oursuccessstory" id="successstories">
        <div class="container-fluid">
          <div class="row mt-3">
            <div class="col-12 col-md-9 col-xl-8 mx-auto text-center">
              <h1 class="section_title" style="color: red">How it works</h1>
              <h2 class="section_subtitle">
                <span class="font-pp_formula_condensedbold">LIGHTWEIGHT MODEL</span>
              </h2>
            </div>
          </div>
          <div class="row align-items-center justify-content-between mb-3">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <!-- Responsive Video Container -->
              <div class="responsive-video">
                <video controls>
                  <source
                    src="https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB/CSS/ILKB_CSS%202.mp4?_t=1729523858"
                    type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="d-flex flex-column">
                <p>
                  With our I Love Kickboxing Lightweight model, you will
                  purchase the required number of kickboxing bags and our
                  integrated Coaching Screen System to successfully run classes
                  at your existing fitness or karate studio.<br /><br />
                  When it's time for your ILKB classes to start, you simply set
                  up our portable and easy-to-move kickboxing bags in your
                  studio space. Turn on the integrated technology at the start
                  of class, and bring the energy while coaching members through
                  the exercises to complete the class!<br /><br />
                  When classes are done for the day or you have other activities
                  or services planned, simply put away your ILKB bags in their
                  designated storage area and continue your operations as
                  normal. Yes, it�s really that simple!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-wrapper section_whyus" id="requirements">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div class="col-12 col-md-8">
              <h1 class="section_title" style="color: red">
                Ready to get started?
              </h1>
              <h2 class="section_subtitle">Franchise Opportunity</h2>
              <p>
                We understand what it takes, and our goal is to ensure you're
                fully prepared to launch your I Love Kickboxing business. We
                offer affordable fixed monthly fees for franchise partners.
                Before proceeding, we'll verify that you meet the financial
                requirements to become a franchise partner
              </p>
            </div>
            <div class="col-12 col-md-4">
              <div class="investment_textbox">
                <h3>2 or 3</h3>
                <p>YEAR TERM</p>
              </div>
              <div class="investment_textbox">
                <h3><span class="currency_text">$</span>2,500</h3>
                <p>INITIAL FRANCHISE FEE</p>
              </div>
              <div class="investment_textbox">
                <h3>~1,000 sq. ft</h3>
                <p>SPACE REQUIREMENT</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Start Process -->
      <section class="section-wrapper section_process" id="process">
        <div class="container-fluid">
          <div class="requirement_full_img mb-5">
            <div v-for="(banner, index) in ProcessBanners" :key="index">
              <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap">
                <img :src="banner.imgurl" />
              </div>
              <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap">
                <img :src="banner.imgurl" />
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-12 col-md-4">
              <h2>7 STEPS TO OWNERSHIP</h2>
              <div v-for="(banner, index) in ProcessBanners2" :key="index">
                <div v-if="banner.device == 'Desktop'" class="d-none d-md-block img-wrap stepowner_img">
                  <img :src="banner.imgurl" />
                </div>
                <div v-if="banner.device == 'Mobile'" class="d-block d-md-none img-wrap stepowner_img">
                  <img :src="banner.imgurl" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="">
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>01</span> FILL OUT THE FORM BELOW
                  </h4>
                  <p>
                    Once we receive your form, a representative will reach out
                    to discuss the next steps.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>02</span> COMPLETE THE OFFICIAL APPLICATION
                  </h4>
                  <p>
                    Provide us with insight into your business experience,
                    financial capabilities, goals and interests, and management
                    style.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>03</span> REVIEW OUR FRANCHISE DISCLOSURE DOCUMENT
                    (FDD)
                  </h4>
                  <p>
                    We send you the official Franchise Disclosure Document that
                    outlines financial and performance information.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>04</span> COMPLETE FRANCHISE VALIDATION AND DUE
                    DILIGENCE
                  </h4>
                  <p>
                    Talk to current Franchise Owners to gain insights about
                    day-to-day operations and their overall experience.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>05</span> ATTEND DISCOVERY SESSIONS
                  </h4>
                  <p>
                    Meet with our team to learn more about ILKB and confirm it's
                    the right opportunity for you.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>06</span> EXECUTE FRANCHISE AGREEMENT
                  </h4>
                  <p>
                    Once our team approves your application and we have all the
                    legally required documentation, we create your Franchise
                    Agreement.
                  </p>
                </div>
                <div class="stepsown_row">
                  <h4 class="stepsown_title">
                    <span>07</span> FIND YOUR LOCATION AND GET STARTED
                  </h4>
                  <p>
                    After the Franchise Agreement is signed, our team works with
                    you on site selection and marketing to support your journey
                    toward opening day.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Process -->

      <!-- Start Press -->
      <section class="section-wrapper section_press" id="press">
        <div class="inthenews_wrap">
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
          <div class="inthenews_text">In the <span>News</span></div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <Splide :options="sliderOptions2">
                <SplideSlide>
                  <div class="press_box">
                    <div class="press_card">
                      <h3 class="press_title">
                        9ROUND KICKBOXING ACQUIRES I LOVE KICKBOXING
                      </h3>
                      <div class="press_link">
                        <a href="https://www.franchisetimes.com/franchise_mergers_and_acquisitions/9round-kickboxing-acquires-ilovekickboxing/article_7c1ad296-e881-11ee-a394-2f6e523631d1.html"
                          target="_blank">
                          Franchise Times
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 1L1 15" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M3.33301 1H14.9997V12.6667" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="press_box">
                    <div class="press_card">
                      <h3 class="press_title">
                        SEE WHY THE LOCALIST SD LOVES KICKBOXING
                      </h3>
                      <div class="press_link">
                        <a href="https://fox5sandiego.com/thelocalistsd/see-why-we-love-kickboxing/" target="_blank">
                          Fox 5 San Diego
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 1L1 15" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M3.33301 1H14.9997V12.6667" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </section>
      <!-- End Press -->
    </template>

    <GetStarted />
    <Footer />
  </div>
</template>